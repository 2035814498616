body{
  /* height: 100%;
  max-height: 100%; */
  background-color: #F5F5F5;
  overflow-y: hidden;
}
/** {*/
/*  outline: 1px solid #f00 !important;*/
/*}*/
.App {
  text-align: center;
}
.inbox{
  overflow-y: scroll;
  /* max-width: 625px; */
  width: 100%;
}
.test1{
  width: 100%;
  overflow-y: hidden;
}
/* Top Bar */

.top-bar{
  /* border-bottom: 1px solid#b3b3b3; */
  background: rgba(245, 245, 245, 1);
  box-shadow: 0px 6.400000095367432px 14.399999618530273px 0px rgba(0, 0, 0, 0.07);
  z-index: 1;
  height: 70px;
  max-height: 70px;
}

.border-right{
  border-right: 1px solid#b3b3b3;
}
.border{
  border: 1px solid#b3b3b3;
}
.border-top{
  border-top: 1px solid#b3b3b3;
}
.border-bottom{
  border-bottom: 1px solid#b3b3b3;
}
.background-grey{
  background: rgb(247, 249, 250) none repeat scroll 0% 0%;
}
.bold{
  font-weight: bold;
}
.inbox{
  background-color: #F5F5F5;
  /* display: 'block';
  max-height: 100%;
  padding: 0; */
  /* width: 450; */
}
.selected{
  background-color: rgb(232, 244, 251);
}
.pdf-wraper{
  display: block;
  position: relative;
  text-align: center;
  height: 100%;
  max-height: 100vh;
  width: 100%;
}
.pdf{
  width: 100%;
  overflow-x: auto;
  height: 100%;
  position: relative;
  padding-bottom: 50px;
  padding-top: 0px;
}
.progress-bar{
  border-bottom: #3b3b3b solid 4px;
  margin-top: 10px;
  margin-right: 10px;
}
.doc-icon{
  font-size: 40px;
}
.waiting{
  color: #1872E7;
}
.signed {
  color: #38C172;
}
.rejected {
  color: #DC3030;
}
.inlineflex{
  display: inline-block;
}
.avatar-placeholder{
  height: 32px;
  width: 32px;
  border-radius: 50%;
  font-size: 0.75rem;
  font-weight: 700;
  color: rgb(255, 255, 255);
  background-color: rgb(13, 30, 46);
  text-transform: uppercase;
  line-height: 32px;
  text-align: center;
  min-width: 32px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.loader {
  color: #1872E7;
  font-size: 10px;
  margin: 100px auto;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@media screen and (min-width: 1024px) {

  .inbox{
    max-width: 625px;
  }
}
@media screen and (max-width: 1024px) {
  .inbox{
    max-width: 100%;
    min-width: 420px;
  }
  #body-container{
    height: 100% !important;
    max-height: initial !important;
    overflow-x: hidden;
  }
  .document-container {
    height: 90% !important;
  }
  .document-pivot [role="tablist"] {
    max-width: 100%;
  }
  .document-modal{
    width: 100% !important;
    margin: 0;
  }
}
@media screen and (max-width: 812px) {
  .top-bar{
    height: 120px;
    max-height: 120px;
  }
  .ms-SearchBox{
    max-width: 125px;
  }
  .settings-text-title {
    font-size: 16px !important;
  }
}
