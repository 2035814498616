.fault-icon{
    background: #FBE7E7;
    border-radius: 12px;
}
.fault-text-title{
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.03999999910593033px;
    text-align: center;
    padding-top: 24px;
    padding-bottom: 24px;
}
.fault-text{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.03999999910593033px;
    text-align: center;
    max-width: 560px;
}
.fault-email{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.03999999910593033px;
    text-align: center;
    color: #1872E7;
}
.fault-text-subtitle{
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.1599999964237213px;
    text-align: center;
}
.fault-info{
    width: 80%;
    margin-top: 36px !important;
}
.fault-email-icon{
    margin: auto;
}