.container{
    max-width: 100%;
}
.settings-button {
    /*margin-top: 32px !important;*/
    /*margin-bottom: 40px !important;*/
    margin-top: 20px !important;
    margin-right: 16px !important;
}

.settings-button button {
    background: #6264A7;
    width: 280px;
    height: 32;
    color: #FFFFFF;
}

.settings-text-title {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    max-width: 100%;
}

.settings-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
}
.settings-text-error{
    font-size: large;
    color: red;
    margin-top: 10px !important;
    font-weight: bold;
}
.settings-text-button{
    margin-top: 20px !important;
    margin-right: 16px !important;
}
@media screen and (max-width: 1024px) {
    .image-wrapper{
        display: none;
    }
}