.inbox-items-container {
    padding: 16px;
    background-color: #F5F5F5;
}

.inbox-item-container {
    margin-bottom: 16px;
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.07);
}

.inbox-item-title {

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1599999964237213px;
    text-align: left;
}

.inbox-item-date {
    margin-right: 1px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.18000000715255737px;
    text-align: right;
    margin-right: 16px;
}

.inbox-item-trash {
    margin-top: 3px;
}

.selected {
    background: rgba(242, 250, 255, 1);
    border: solid 1px #1872E7;
    box-shadow: 0px 4px 32px 0px rgba(24, 114, 231, 0.08);
}

.inbox-item-container .progressbar-container {
    flex-flow: inherit;
    width: 90px;
    margin-left: 100px;
    margin-top: 5px;
    float: right;
}

.inbox-item-container .progressbar-text {
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.23999999463558197px;
    text-align: right;
    margin-left: 5px;
}

.inbox-item-container .ms-ProgressIndicator-itemProgress {
    /* max-width: 70px; */
    width: 70px;
}

.item-image {
    display: table;
    background: rgba(225, 230, 234, 1);
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 100%;
    text-align: center;
}

.item-image .ms-Image {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.item-image .ms-Image img {
    opacity: 0.7;
}

.viewed {
    background: rgba(245, 245, 245, 1);
}

.item-people {
    padding-left: 10px;
}

.item-sender {
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.23999999463558197px;
    text-align: left;
}

.item-signer {
    height: 16px;
    width: 200px;
    left: 78px;
    top: 66px;
}

.item-text {
    padding-top: 10px;
}

.rejected img {
    filter: invert(28%) sepia(79%) saturate(3584%) hue-rotate(347deg) brightness(92%) contrast(85%);
    opacity: 1 !important;
}

.item-image .rejected {
    background: #FBE7E7 !important;
    border-radius: 100%;
}

.completed img {
    filter: invert(61%) sepia(99%) saturate(308%) hue-rotate(93deg) brightness(86%) contrast(88%);
    opacity: 1 !important;
}

.item-image .completed {
    background: #E3FCEC !important;
    border-radius: 100%;
}

.item-status-text {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.18000000715255737px;
    text-align: right;
}

.message-signed {
    color: #38C172;
}

.message-rejected {
    color: #DC3030;
}

.item-status-status {
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.18000000715255737px;
    text-align: right;
}
.inbox-create-container{
    padding-bottom: 16px;

}
.inbox-create-button{
    background: rgba(24, 114, 231, 1);
    color: #FFFFFF;
    width: 100%;
    height: 53px;
    font-size: large;
}
.status-flag-wrapper{
    margin-bottom: 16px;
    flex-wrap: wrap;
}
.status-flag-wrapper .ms-Link{
    padding: 10px;
} 
.status-flag .ms-ContextualMenu-itemText{
    color: #FFFFFF !important;
}
.status-flag{
    padding: 10px;
}
.active-flag{
    background: rgba(24, 114, 231, 1);
    color: #FFFFFF !important;
}
.active-flag:focus{
    color: #FFFFFF;
}
.active-flag:hover{
    color: #FFFFFF;
}