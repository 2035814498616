.topbar-container{
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
}
.topbar-search-container{
    margin-block-start: 1em;
    margin-block-end: 1em;
    float: right;
    
}
/* .topbar-search-container .ms-SearchBox{
    
} */
.topbar-sort-button{
    /* width: 40px; */
    height: 33px;
}
.topbar-sort-button .text{
    padding-top: 5px;
    padding-right: 10px;
    font-weight: bold;
}
.advanced-search-button{
    margin-left: 10px;

}
.searh-button{
    margin-top: 10px;
}
.ms-ChoiceField{
    margin-right: 10px;
}
@media screen and (max-width: 812px) {
    .topbar-search-container{
        /* padding: auto; */
        /* margin: auto; */
        /* float: left;
        left: 0;
        right: 20px; */
        /* padding-right: 5px; */
    }
  }
  .back-button{
      margin-top: 15px;
      margin-bottom: 9px;
  }