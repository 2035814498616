.document-container {
    height: 100%;
    background-color: #F5F5F5;
    overflow-y: scroll;
    overflow-x: hidden;
}

.document-info {
    background: #FFFFFF;
    z-index: 1;
    max-width: 100%;
}

.document-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.03999999910593033px;
    text-align: left;
    margin-top: 10px;
    max-width: 50%;
}
.details-message{
    background: #FFFFFF;
    padding: 16px;
}

.document-progress {
    padding: 5px;
    width: 100%;
}

.icon {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: rgba(225, 230, 234, 1);
}

.document-progress .progressbar-container {
    width: 100%;
    min-width: 100px;
    padding-left: 10px;
    padding-right: 10px;
}

.document-progress .progressbar-text small {
    float: right;
}

.document-progress-text-top {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.23999999463558197px;
    text-align: left;
    padding-left: 10px;
}

.document-progress-text-bottom {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.18000000715255737px;
    text-align: left;
    padding-left: 10px;
    margin-top: -17px !important;
}

.document-pivot .ms-Pivot {
    box-shadow: 0px 4px 16px -2px rgba(41, 51, 61, 0.08);
    background: #FFFFFF;
}

.document-pivot [role="tabpanel"] {
    background-color: #F5F5F5;
    z-index: 0;
}
.document-pivot [role="tablist"] {
    overflow: hidden;
}

.document-sender-container {
    padding: 10px 10px 0px 10px;
}

.document-pivot .ms-Persona-primaryText .ms-TooltipHost {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.03999999910593033px;
    text-align: left;
}

.ms-Persona-initials {
    background: rgba(225, 230, 234, 1);
}

.document-pivot .ms-Persona-image {
    background: rgba(225, 230, 234, 1);
}

.document-pivot .ms-Persona-image img {
    width: 20px;
    height: 18px;
    display: inline-block;
    margin-top: 10px;
}

.document-sender {
    background: #FFFFFF;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 6px;
    padding-right: 16px;
}

.documet-recipients-container {
    background: #FFFFFF;
    padding: 6px;
    padding-right: 16px;
}

.file-container {
    margin: 16px;
}

.file-title {
    padding-top: 20px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.23999999463558197px;
    text-align: left;
}

.file-ico {
    display: table;
    background: rgba(225, 230, 234, 1);
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 100%;
    text-align: center;
}

.file-ico span {
    display: table-cell;
    vertical-align: middle;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.23999999463558197px;
    color: rgba(105, 125, 142, 1);
}

.history-element{
    margin-bottom: 10px;
}

.history-item {
    padding-left: 16px;
    padding-top: 3px;
}

.history-container {
    padding-top: 26px;
    padding-left: 16px;
}

.history-event {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.23999999463558197px;
    text-align: left;
}

.history-name {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.18000000715255737px;
    text-align: left;
}

.history-timestamp {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.18000000715255737px;
    text-align: left;
}

.document-sign-buttons {
    max-height: 79px;
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0px 4px 16px -2px rgba(41, 51, 61, 0.08);
}

.document-sign-buttons>div>div {
    padding: 16px;
}

.document-sign-buttons button {
    width: 100%;
    height: 48px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.03999999910593033px;

}

.go-to-sign {
    background: rgba(24, 114, 231, 1);
    color: #FFFFFF;
}
.go-to-sign:disabled{
    background: #DDDDDD;
}
.company-name {

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.1599999964237213px;
    text-align: right;

}

.company-taxid {

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.1599999964237213px;
    text-align: right;

}

.history-icon {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    padding-left: 9px;
}

.document-modal {
    overflow: hidden;
    width: 60%;
    position: relative;
}

.pdf-page {
    flex-direction: row;
}

.modal-header {
    padding: 16px;
    display: flex;
    position: absolute;
    top: 0;
    width: 97%;
    z-index: 1;
    background: #ffffff;
    box-shadow: 0px 4px 32px 0px rgba(24, 114, 231, 0.08);
}

.modal-header button {
    float: right;
}

.modal-header span {
    float: left;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.07999999821186066px;
    text-align: left;

}
.iframe{
    height: 97vh;
}
.document-sign-modal{
    /* width: auto; */
    min-width: 400px;
    padding: 16px;
}
.document-sign-modal-header{
    z-index: 1;
    background: #ffffff;
    box-shadow: 0px 4px 32px 0px rgba(24, 114, 231, 0.08);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: -0.07999999821186066px;
    text-align: left;
    padding-bottom: 16px;
}
.document-sign-modal-body{
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
}
.document-sign-modal-body div{
 padding: 8px;
}
.document-sign-modal-footer{
    width: 100%;
    text-align: right;
}
.recipients-desc{
    text-align: right;
    padding-top: 10px;
}
.ms-Pivot .is-selected{
    color: #1872E7 !important;
}
.top-sign-buttons{
    float: right;
}
.top-sign-buttons img{
    width: 15px;
}
.top-sign-buttons .sign-buttons{
    float: right;
    margin-top: 10px;
    padding: 10px;
}
.recipients-details{
    border: none;
    width: 20px;
    margin-top: 10px;
    padding: 0;
    min-width: 30px;
    margin-left: 20px;
    margin-bottom: 20px;
}
.recipients-details span{
    width: 20px;
    margin: 10px;
}
.recipients-details:hover{
    background: none;
}
.details-button-container{
    padding-right: 0;
    width: 60px;
}
.recipient-details-text{
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 18px;
letter-spacing: 0.1599999964237213px;
text-align: left;
margin-bottom: 10px;
}
.recipient-details-container{
    padding: 16px;
    background:#FFFFFF;
}
.recipient-details-border{
    border-top: 1px solid #E1E6EA;
    margin-bottom: 10px;
}
.recipient-details-container span{
    padding: 5px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.1599999964237213px;
    text-align: left;
}
.recipient-details-bold{
    font-weight: bold !important;
    color: #000000 !important;
}
@media screen and (max-width: 812px) {
    .top-sign-buttons{
        float: initial !important;
        text-align: center;
    }
    .top-sign-buttons .sign-buttons{
        float: none;
        margin-top: 0;
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
        display: inline-block;
    }
    .document-title{
        width: 100%;
    }
    .recipients-desc{
        text-align: center;
    }
  }